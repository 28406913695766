import Container from './Container';

const AuthPageWrapper = ({ children }) => {
  return (
    <Container className='relative'>
      <div className='grid grid-cols-12 min-h-full justify-center lg:py-24 sm:px-6 lg:px-8 lg:gap-x-12 gap-y-8 pb-24'>
        <div className='col-span-12 lg:col-span-8 aspect-video -mx-6 lg:mx-0'>
          <img src='/helpful-hunter.jpg' alt='bg artwork' className='w-full h-full object-cover lg:rounded-lg' />
        </div>

        <div className='col-span-12 lg:col-span-4'>{children}</div>
      </div>
    </Container>
  );
};

export default AuthPageWrapper;
