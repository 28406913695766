import { useFormContext } from 'react-hook-form';

const FormInput = ({ label, type, placeholder, required, autoComplete, className, name, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor={name} className='block text-sm/6 font-medium text-gray-900'>
        {label}
      </label>
      <div className='mt-2'>
        <input
          type={type}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          className={`block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50${className}`}
          {...register(name, {
            required: required ? 'This field is required' : false,
          })}
          {...props}
        />
      </div>
      {errors[name] && (
        <div className='mt-2'>
          <p className='text-red-500 text-sm'>{errors[name].message}</p>
        </div>
      )}
    </div>
  );
};

export default FormInput;
