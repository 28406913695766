import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import AuthFormWrapper from '../../components/AuthFormWrapper';
import Form from '../../components/Form';
import FormInput from '../../components/FormInput';
import { auth, handleEmailSignIn, handleGoogleSignIn } from '../../utilities/firebase';
import { LoginSchema } from '../../utilities/zod';
import { useTranslation } from '@/hooks/useTranslation';

const LoginForm = ({ type = 'login' }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {},
    resolver: zodResolver(LoginSchema),
  });

  const emailMutation = useMutation({
    mutationFn: handleEmailSignIn,
    onSuccess: () => {
      toast.success(t('auth.signIn.checkEmail'));
    },
    onError: (error) => {
      console.error('Error signing in with email:', error);
      toast.error(t('auth.signIn.errors.failedEmail'));
    },
  });

  const onSubmit = (data) => {
    emailMutation.mutate(data.username);
  };

  const googleMutation = useMutation({
    mutationFn: handleGoogleSignIn,
    onSuccess: () => {
      router.push('/');
    },
    onError: (error) => {
      console.error('Error signing in with Google:', error);
      toast.error(t('auth.signIn.errors.failedGoogle'));
    },
  });

  const checkFinishSignUp = useCallback(async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (!router.query.email) {
        toast.error(t('auth.signIn.errors.noEmail'));
        return;
      }

      try {
        await signInWithEmailLink(auth, router.query.email, window.location.href);
        router.push('/');
      } catch (error) {
        console.error('Error signing in with email:', error);
        toast.error(t('auth.signIn.errors.failedEmail'));
      }
    }
  }, [router.query.email, t]);

  useEffect(() => {
    checkFinishSignUp();
  }, [checkFinishSignUp]);

  return (
    <AuthFormWrapper heading={type === 'login' ? t('auth.signIn.title') : t('auth.register.title')}>
      <div className='flex items-center flex-col justify-center gap-4'>
        <button type='button' disabled={googleMutation.isPending} onClick={googleMutation.mutate} className='w-full flex items-center justify-center gap-3 border border-gray-200 rounded-lg p-2'>
          <img src='/google.png' alt='Google' className='w-5 h-5' />
          <span>{t('auth.signIn.withGoogle')}</span>
        </button>
      </div>

      <div className='relative my-6'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-200'></div>
        </div>
        <div className='relative flex justify-center text-sm/6 font-medium'>
          <span className='bg-white px-6 text-gray-900'>{t('auth.signIn.withEmail')}</span>
        </div>
      </div>

      <Form form={form} onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
        <FormInput label={t('common.email')} name='username' type='email' required autoComplete='email' />

        <div>
          <Button disabled={emailMutation.isPending} className='w-full' type='submit'>
            {t('auth.signIn.sendMagicLink')}
          </Button>
        </div>

        <div className='flex items-center justify-center gap-4'>
          <div className='text-sm/6'>
            {type === 'login' ? (
              <Link href='/register' className='font-semibold text-green-600 hover:text-green-500'>
                {t('auth.signIn.noAccount')}
              </Link>
            ) : (
              <Link href='/login' className='font-semibold text-green-600 hover:text-green-500'>
                {t('auth.register.haveAccount')}
              </Link>
            )}
          </div>
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[375px]'>
          <p className='text-center text-sm text-gray-500'>
            {type === 'login' ? t('auth.signIn.terms') : t('auth.register.terms')}{' '}
            <Link href='/terms-of-service' className='font-semibold text-green-600 hover:text-green-500'>
              {t('auth.common.termsOfService')}
            </Link>{' '}
            {t('auth.common.and')}{' '}
            <Link href='/privacy-policy' className='font-semibold text-green-600 hover:text-green-500'>
              {t('auth.common.privacyPolicy')}
            </Link>
            .
          </p>
        </div>
      </Form>
    </AuthFormWrapper>
  );
};

export default LoginForm;
