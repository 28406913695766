import Logo from './Logo';

const AuthFormWrapper = ({ children, heading }) => {
  return (
    <div className='sm:mx-auto sm:w-full h-full'>
      <div className='bg-white px-6 py-12 border border-gray-200 rounded-lg sm:px-12 h-full flex flex-col justify-center'>
        <div className='flex items-center justify-center mb-4'>
          <Logo className='text-black h-[28px]' />
        </div>

        <div className='sm:mx-auto sm:w-full sm:max-w-md mb-8'>
          <h2 className='text-center text-2xl/9 font-bold tracking-tight text-gray-900'>{heading}</h2>
        </div>

        <div className='max-w-[375px] mx-auto'>{children}</div>
      </div>
    </div>
  );
};

export default AuthFormWrapper;
